import { useEffect } from 'react';

const Global404 = () => {
  useEffect(() => {
    const currentPath = window.location.pathname;
    const locale = currentPath.startsWith('/ar') ? 'ar' : 'en';
    const localized404Path = `/${locale}/404`;
    if (currentPath !== localized404Path) {
      window.location.href = localized404Path;
    }
  }, []);

  return (
    <div>
      <h1>404 - Page Not Found</h1>
    </div>
  );
};

export default Global404;
